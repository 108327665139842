@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-100;
  }
}

@layer components {
  .sidebar-basis{
    transition:all .4s ease-in-out;
  }
}
